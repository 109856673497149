<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-button
        class="btn btn-gradient-primary mb-1 mr-50 col-md-2"
        @click="$router.go(-1)"
      >
        <i class="fas fa-arrow-left mr-25" /> กลับไปหน้ารายการ
      </b-button>
      <b-button
        class="btn btn-gradient-success mb-1 ml-25 mr-50 col-md-2"
        @click="Submit()"
      >
        บันทึกข้อมูล
      </b-button>

      <b-row class="match-height">
        <b-col md="6">

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                แก้ไขสมุดบัญชี
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      รหัสลูกค้า :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Customer Code"
                      rules="required"
                    >
                      <v-select
                        v-model="username_cus"
                        label="username"
                        :options="cuslist"
                        :reduce="username => username.username"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-remark"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      ประเภท :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <b-form-select
                        v-model="type"
                        :options="typeOp"
                        :state="errors.length > 0 ? false:null"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-remark"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      รายละเอียด :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <b-form-textarea
                        id="detail"
                        v-model="detail"
                        :state="errors.length > 0 ? false:null"
                        placeholder="รายละเอียด"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-remark"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      จำนวน :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Amount"
                      rules="required"
                    >
                      <b-form-input
                        id="amount"
                        v-model="amount"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="จำนวน"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-remark"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      Prefix :
                    </template>
                    <b-form-input
                      id="prefix"
                      v-model="prefix"
                      type="text"
                      placeholder="Prefix"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-remark"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      Prefix Numbe :
                    </template>
                    <b-form-input
                      id="prefixNum"
                      v-model="prefixnumber"
                      type="number"
                      placeholder="Prefix Number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div
            class="card"
          >
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                ข้อมูลเพิ่มเติม
              </h3>
            </div>

            <div class="p-2">
              Other
            </div>

          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BButton,
    BFormInput,
    BFormSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      typeOp: [
        { value: 1, text: 'เติมเงิน' },
        { value: 2, text: 'ถอนเงิน' },
        { value: 3, text: 'ชำระค่านำเข้า' },
        { value: 4, text: 'ค่าสินค้า' },
        { value: 5, text: 'ส่วนต่างค่าขนส่งในไทย' },
        { value: 6, text: 'ค่าสินค้าใน MALL' },
        { value: 7, text: 'ฝากจ่ายเถาเปา' },
        { value: 8, text: 'ค่า Commission' },
        { value: 9, text: 'เติมเงินอาลีเพย์' },
        { value: 10, text: 'โอนเงินไปจีน' },
        { value: 11, text: 'แจ้งไม่ได้รับสินค้า' },
        { value: 12, text: 'แจ้งร้านค้าคืนเงิน' },
        { value: 13, text: 'ค่าจัดส่งในไทย' },
        { value: 14, text: 'แลกเงินหยวนเป็นเงินบาท' },
        { value: 15, text: 'ส่งของไปจีน' },
        { value: 16, text: 'แลกแต้ม' },
        // { value: 17, text: 'อื่นๆ' },
      ],
      id: null,
      cuslist: [],
      username_cus: '',
      detail: '',
      amount: 0,
      prefix: '',
      prefixnumber: '',
      type: null,
    }
  },
  created() {
    this.$http.get('/users/cuslist').then(res => {
      this.cuslist = res.data
    })

    this.$http.get('')
  },
  mounted() {
  },
  methods: {
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const index = this.typeOp.findIndex(x => x.value === this.type)
          const params = {
            _id: this.id,
            username_cus: this.username_cus,
            type_text: this.typeOp[index].text,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixnumber: this.prefixnumber,
          }
          this.$http.post('transactions/storeAndupdate', params)
            .then(() => {
              this.getData(this.isActive)
              this.id = null
              this.username_cus = null
              this.type = null
              this.detail = null
              this.amount = 0
              this.prefix = null
              this.prefixnumber = null
              this.Success()
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
