var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-button',{staticClass:"btn btn-gradient-primary mb-1 mr-50 col-md-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fas fa-arrow-left mr-25"}),_vm._v(" กลับไปหน้ารายการ ")]),_c('b-button',{staticClass:"btn btn-gradient-success mb-1 ml-25 mr-50 col-md-2",on:{"click":function($event){return _vm.Submit()}}},[_vm._v(" บันทึกข้อมูล ")]),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"header-cardx p-2"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" แก้ไขสมุดบัญชี ")])]),_c('div',{staticClass:"p-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"h-paecelCode","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" รหัสลูกค้า : ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Customer Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"username","options":_vm.cuslist,"reduce":function (username) { return username.username; }},model:{value:(_vm.username_cus),callback:function ($$v) {_vm.username_cus=$$v},expression:"username_cus"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"h-remark","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" ประเภท : ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.typeOp,"state":errors.length > 0 ? false:null},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"h-remark","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" รายละเอียด : ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"detail","state":errors.length > 0 ? false:null,"placeholder":"รายละเอียด"},model:{value:(_vm.detail),callback:function ($$v) {_vm.detail=$$v},expression:"detail"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"h-remark","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" จำนวน : ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"amount","type":"number","state":errors.length > 0 ? false:null,"placeholder":"จำนวน"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"h-remark","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Prefix : ")]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"prefix","type":"text","placeholder":"Prefix"},model:{value:(_vm.prefix),callback:function ($$v) {_vm.prefix=$$v},expression:"prefix"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"h-remark","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Prefix Numbe : ")]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"prefixNum","type":"number","placeholder":"Prefix Number"},model:{value:(_vm.prefixnumber),callback:function ($$v) {_vm.prefixnumber=$$v},expression:"prefixnumber"}})],1)],1)],1)],1)])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"header-cardx p-2"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" ข้อมูลเพิ่มเติม ")])]),_c('div',{staticClass:"p-2"},[_vm._v(" Other ")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }